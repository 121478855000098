<template>
  <div>
    <div v-if="selectedDateBookable">
      <div v-for="note in notes" :key="note" class="nt-alert-block nt-alert-block--info">
        <div class="nt-alert-block__content nt-alert-block__content--info">
          <p v-html="note"></p>
        </div>
      </div>
      <div class="nt-hol-availability-book-summary" aria-label="Booking summary">
        <div class="calendarSummary-block nt-horizontal-separator" data-tst="month-fully-booked">
          <span>Try changing the month or number of nights to see more availability.</span>
        </div>
        <div class="nt-checkin-checkout-block calendarSummary--2colSplit calendarSummary-block nt-horizontal-separator">
          <div data-tst="checkin-date-time">
            <div class="nt-text-highlight">Check-in </div>
            <div>{{checkinDate}} at <time>{{checkinTime}}</time></div>
          </div>
          <div data-tst="checkout-date-time">
            <div class="nt-text-highlight">Check-out </div>
            <div>{{checkoutDate}} at <time>{{checkoutTime}}</time></div>
          </div>
        </div>
        <div v-if="deposit" data-tst="deposit-wrapper" class="calendarSummary-block nt-noPadding">
          <div><span data-tst="deposit">Deposit to secure holiday</span><span style="float: right">&pound;{{selectedDate.holiday.depositCost}}</span></div>
          <div><span data-tst="balance-due">{{balanceDueMessage}}</span><span style="float: right">&pound;{{selectedDate.holiday.depositBalanceRemaining}}</span></div>
        </div>
        <div v-if="discountMessage" data-tst="discounted.desc" class="calendarSummary--2colSplit__spaceBetween calendarSummary-block nt-noPadding">{{selectedDate.holiday.discountDescription}}</div>
        <div class="calendarSummary--2colSplit__spaceBetween calendarSummary-block nt-text-highlight nt-noPadding" data-tst="summary">
          <div class="nt-text-highlight">Total to pay</div>
          <div>
            <span v-if="discounted" data-tst="discounted.original"><s>&pound;{{selectedDate.holiday.originalCost}}</s></span>&nbsp;
            <span class="nt-text-highlight" data-tst="cost">&pound;{{selectedDate.holiday.actualCost}}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isCluster">
      <p data-tst="cluster-info">To book this holiday, please ring the holidays team on 0344 800 2070.</p>
    </div>
    <div v-else>
      <div v-if="!selectedDateBookable" class="nt-hol-availability-book-summary" data-tst="month-fully-booked">
        <span>Try changing the month or number of nights to see more availability.</span>
      </div>
      <div v-if="selectedDateBookable" class="nt-hol-availability-book__button">
        <a class="button round nt-button primary small" :href="bookingUrl" data-tst="book">Book now</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { addDays, format, isSunday } from 'date-fns';
import util from '../util';

const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct',
  'Nov', 'Dec'];

const SUNDAY_CHECK_OUT_TIME = '16:00';
const DEFAULT_CHECK_OUT_TIME = '10:00 AM';
const DEFAULT_CHECK_IN_TIME = '16:00';
const CHECK_IN_OUT_DATE_FORMAT = 'dd/MM/yyyy';
export default {

  computed: {
    ...mapGetters('availabilityStore', ['cluster', 'selectedDate', 'selectedMonth', 'numberOfNights', 'travellerId', 'calendar']),
    notes() {
      return this.selectedDate.notes;
    },
    discounted() {
      return this.selectedDateBookable && this.selectedDate.holiday.discounted;
    },
    discountMessage() {
      return this.selectedDateBookable && this.selectedDate.holiday.discountDescription;
    },
    balanceDueMessage() {
      const balanceDue = util.newDate(`${this.selectedDate.holiday.depositBalanceDueDate}T00:00:00`);
      const month = months[balanceDue.getMonth()];
      const day = balanceDue.getDate();
      const year = balanceDue.getFullYear();
      return `Balance due ${day} ${month} ${year}`;
    },
    deposit() {
      return this.selectedDateBookable && this.selectedDate.holiday.depositCost;
    },
    bookingUrl() {
      if (this.selectedDateBookable) {
        const startDate = this.selectedDate.dateString;
        const path = `/holidays/booking?accommodationId=${this.travellerId}&holidayStartDate=${startDate}&numberOfNights=${this.numberOfNights}`;
        return `${path}`;
      }
      return '#';
    },
    selectedDateBookable() {
      return this.selectedDate && this.selectedDate.holiday && this.selectedDate.holiday.bookable === true;
    },
    checkinDate() {
      return format(this.selectedDate.date, CHECK_IN_OUT_DATE_FORMAT);
    },
    checkoutDate() {
      const checkoutDate = addDays(this.selectedDate.date, this.selectedDate.holiday.numberOfNights);
      return format(checkoutDate, CHECK_IN_OUT_DATE_FORMAT);
    },
    checkinTime() {
      return DEFAULT_CHECK_IN_TIME;
    },
    checkoutTime() {
      const checkoutDate = addDays(this.selectedDate.date, this.selectedDate.holiday.numberOfNights);
      return isSunday(checkoutDate) ? SUNDAY_CHECK_OUT_TIME : DEFAULT_CHECK_OUT_TIME;
    },
    isCluster() {
      return this.cluster || (this.selectedDate && this.selectedDate.holiday && this.selectedDate.holiday.cluster === true);
    },
    wholeMonthBookedUp() {
      const thisMonthsCalendars = Object.entries(this.calendar)
        .filter(entry => entry[0].startsWith(this.selectedMonth))
        .map(entry => entry[1]);
      return thisMonthsCalendars.every(cal => !(cal.holiday && cal.available && cal.holiday.bookable));
    },
  },
};
</script>

<style lang="scss" scoped>
  .button {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.2;
    display: inline-block;
    border-style: solid;
    border-width: 0;
    text-decoration: none;
    text-align: center;
    padding: 0.65em 0;
    background-color: transparent;
    color: #2c2c2c;
    cursor: pointer;
    transition: background-color 300ms ease-out 0s;
    transition: border-color 300ms ease-out 0s;
  }

  .nt-button {
    background-color: transparent;
    color: currentColor;
    padding: 0.8em 0;
    border-width: 0.1em;
    border-style: solid;
    transition: background-color 0.5s ease;
  }

  .nt-hol-availability-book__button {
    margin-bottom: 10px;
  }

  .calendarSummary-block {
    padding: 0 0 16px 0;
    width: 100%;
  }
  .calendarSummary--2colSplit>div:not(:last-child) {
    padding-bottom: 16px;
  }
  .calendarSummary--2colSplit__spaceBetween>div:not(:last-child) {
    padding-right: 8px; /* eliminate columns clashing */
  }
  .calendarSummary--2colSplit__spaceBetween {
    display: flex;
    justify-content: space-between;
  }
  .nt-horizontal-separator {
    border-bottom: 1px solid #707070;
  }
  .nt-noPadding {
    padding-bottom: 0;
  }

  .nt-hol-availability-book-summary {
    container-name: summary;
    container-type: inline-size;
  }

  @container summary (width > 340px) {
    .calendarSummary--2colSplit {
      display: flex;
      gap: 24px;
    }

    .calendarSummary--2colSplit>div:not(:last-child) {
      padding-right: 24px;
      padding-bottom: 0 !important;
      border-right: 1px solid #707070;
    }
  }
</style>
