import Vue from 'vue';
import VCalendar from 'v-calendar';
import App from './App.vue';
import store from './store/store';

Vue.use(VCalendar);

Vue.config.productionTip = false;

if (window.travellerId) {
  new Vue({
    store,
    render: h => h(App),
  }).$mount('#holidays-availability-calendar');
}
