/* eslint-disable no-param-reassign */
import axios from 'axios';
import util from '../util';

function initialFromDate() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

function initialToDate() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

function minDate(dates) {
  dates.sort((d1, d2) => d1 - d2);
  return dates[0];
}

function maxDate(dates) {
  dates.sort((d1, d2) => d2 - d1);
  return dates[0];
}

// eslint-disable-next-line no-underscore-dangle
function _setSelectedDate(state, selectedDate) {
  const dateString = util.dateString(selectedDate);
  state.selectedDate = {
    ...state.calendar[dateString],
    dateString,
  };
}

// eslint-disable-next-line no-underscore-dangle
function _setSearchStartDate(state, searchStartDate) {
  // state.searchStartDate = util.dateString(searchStartDate);
  state.searchStartDate = searchStartDate;
}

function bookable(state, selectedDate) {
  const selectedDateString = util.dateString(selectedDate);
  const selectedCalendar = state.calendar[selectedDateString];
  const { available, holiday } = selectedCalendar;
  return available === true && holiday && holiday.bookable === true;
}

/*
  Check that the date overlaps with any date range, taking number of nights into account.
  Example of a notes section for reference.
  "notes": [
    {
      "dateRange": {
        "start": "2020-02-08",
        "end": "2020-02-15"
      },
      "note": "test timed errata"
    }
  ]
 */
function getArrayOfApplicableNotes(date, numberOfNights, notes) {
  const resultNotes = [];
  if (!notes) {
    return [];
  }
  notes.forEach((note) => {
    const rangeEnd = util.newDate(`${note.dateRange.end}T00:00:00`);
    const rangeStart = util.newDate(`${note.dateRange.start}T00:00:00`);
    // we need to see if the date passed in is within the range of each note, so we extend the range
    // by the required number of nights.
    rangeStart.setDate(rangeStart.getDate() - (numberOfNights - 1));
    if (date >= rangeStart && date <= rangeEnd) {
      resultNotes.push(note.note);
    }
  });
  return resultNotes;
}

export default {
  namespaced: true,
  state: {
    loading: true,
    calendar: {},
    selectedDate: undefined,
    searchStartDate: undefined,
    selectedMonth: undefined,
    numberOfNights: undefined,
    fromDate: initialFromDate(),
    toDate: initialToDate(),
    nightOptions: [],
    travellerId: window.travellerId,
    searchableMonths: [],
    cluster: undefined,
  },

  getters: {
    loading: state => state.loading,
    calendar: state => state.calendar,
    selectedDate: state => state.selectedDate,
    searchStartDate: state => state.searchStartDate,
    selectedMonth: state => state.selectedMonth,
    numberOfNights: state => state.numberOfNights,
    fromDate: state => state.fromDate,
    toDate: state => state.toDate,
    nightOptions: state => state.nightOptions,
    travellerId: state => state.travellerId,
    searchableMonths: state => state.searchableMonths,
    cluster: state => state.cluster,
  },

  mutations: {
    updateAvailability: (state, payload) => {
      state.numberOfNights = payload.availability.numberOfNights;
      state.searchableMonths = payload.formOptions.months;
      state.searchableMonths.forEach((month) => {
        const monthYear = month.displayName.split(' ');
        const mon = monthYear[0];
        const year = monthYear[1];
        month.shortDisplayName = `${mon.substring(0, 3)} ${year}`;
      });
      const { notes } = payload.availability;
      state.calendar = payload.availability.dates
        .reduce((calendar, date) => {
          const jsDate = util.newDate(date.date);
          calendar[date.date] = {
            ...date,
            date: jsDate,
            notes: getArrayOfApplicableNotes(jsDate, state.numberOfNights, notes),
          };
          return calendar;
        }, {});

      // choose the pre-selected start date from the server
      if (!state.selectedDate && payload.selectedDates.startDate) {
        const searchStartDate = util.newDate(`${payload.selectedDates.startDate}T00:00:00`);
        _setSelectedDate(state, searchStartDate);
        _setSearchStartDate(state, searchStartDate);
      }

      if (state.selectedDate) {
        const selectedCalendar = state.calendar[state.selectedDate.dateString];
        if (selectedCalendar) {
          if (bookable(state, selectedCalendar.date)) {
            // update the selected date with the new calendar data (e.g. pricing)
            _setSelectedDate(state, state.selectedDate.date);
          } else {
            // the selected date is not bookable anymore; unset it
            state.selectedDate = undefined;
          }
        }
      }

      state.selectedMonth = payload.selectedDates.month;

      // because IE (all versions including edge) resolves '2020-01' as '2019-12-31T16:00:00'
      const dates = payload.formOptions.months.map(month => util.newDate(`${month.value}-01T00:00:00`));
      state.fromDate = minDate(dates);
      state.toDate = maxDate(dates);

      state.nightOptions = payload.formOptions.nights.map((night) => {
        const value = parseInt(night.value, 10);
        let display = `${night.displayName} night`;
        if (value > 1) {
          display += 's';
        }
        return {
          value,
          display,
        };
      });

      state.loading = false;
      state.cluster = payload.cluster ? payload.cluster : false;
    },

    setSelectedDate: (state, selectedDate) => {
      _setSelectedDate(state, selectedDate);
    },

    setSearchStartDate: (state, searchStartDate) => {
      _setSearchStartDate(state, searchStartDate);
    },

    setLoading: (state, loading) => {
      state.loading = loading;
    },
  },

  actions: {
    fetchAvailability: ({ commit, state }, { month, year, nights }) => {
      commit('setLoading', true);
      let url = `/holidays/data/accommodation/${state.travellerId}/availability-calendar-data`;
      if (month && year && nights) {
        const monthAndYear = `${year}-${month.toString().padStart(2, '0')}`;
        url += `?month=${monthAndYear}&nights=${nights}`;
      }
      return axios
        .get(url)
        .then((res) => {
          commit('updateAvailability', res.data);
        });
      // todo catch error
    },

    updateSelectedDate: ({ commit, state }, selectedDate) => {
      if (bookable(state, selectedDate)) {
        commit('setSelectedDate', selectedDate);
      }
    },
  },
};
