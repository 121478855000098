const newDate = (dateStr) => {
  const year = parseInt(dateStr.substring(0, 4), 10);
  const month = parseInt(dateStr.substring(5, 7), 10) - 1;
  const date = parseInt(dateStr.substring(8, 10), 10);
  return new Date(year, month, date);
};

const dateString = (jsDate) => {
  const year = jsDate.getFullYear();
  let month = (jsDate.getMonth() + 1).toString();
  let date = (jsDate.getDate()).toString();

  if (month.length === 1) {
    month = `0${month}`;
  }

  if (date.length === 1) {
    date = `0${date}`;
  }
  return `${year}-${month}-${date}`;
};

export default {
  newDate,
  dateString,
};
