<template>
  <v-calendar
    ref="calendar"
    is-expanded
    nav-visibility="hidden"
    transition="none"
    @update:from-page="onNextMonth"
    :min-date="this.fromDate"
    :max-date="this.toDate"
    :first-day-of-week="2">

    <template v-slot:header-title="props">
      <div class="nt-hol-availability-book__header">
        <select v-model="yearMonth" @change="updateDates(props, $event)" data-tst="month">
          <option v-for="month in searchableMonths"
                  :key="month.value" :value="month.value">{{month.shortDisplayName}}</option>
        </select>
        <select class="nt-hol-availability-book__duration" @change="updateNights" data-tst="nights">
          <option
            :data-tst="`night-${option.value}`"
            v-for="option in nightOptions"
            :key="option.value"
            :value="option.value"
            :selected="option.value === numberOfNights">{{option.display}}</option>
        </select>
      </div>
    </template>

      <template v-slot:day-content="props">
          <button
              aria-role="button"
              :aria-label="props.day.date.toDateString()"
              class="nt-day" :class="getClassForDay(props)"
              v-on:click="onDayClick(props.day)"
              :data-tst="getDateString(props.day.date)">
            <div class="nt-day-content">
              <span data-tst="month-label"
                    class="nt-month-label">{{ isFirstOfMonth(props.day) ? monthLabel(props.day) : '\xa0' }}</span>
              <span data-tst="label">{{props.day.label}}</span>
              <span v-if="hasPrice(props)" data-tst="price"
                    class="nt-hol-availability-calendar__price nt-holiday-price">&pound;{{getPriceForDay(props)}}</span>
              <span v-else data-tst="no-price" class="nt-hol-availability-calendar__price"/>
            </div>
          </button>
      </template>

  </v-calendar>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import util from '../util';

function available(cal) {
  return cal && cal.available;
}

function bookable(cal) {
  return available(cal) && cal.holiday && cal.holiday.bookable;
}
function sameMonth(date1Str/* 2020-01 */, date2) {
  // -1 because dates are 0-indexed in Date
  const date1Month = parseInt(date1Str.substring(5, 7), 10) - 1;
  return date1Month === date2.getMonth();
}

export default {
  data() {
    return {
      yearMonth: undefined,
    };
  },

  computed: {
    ...mapGetters('availabilityStore',
      ['calendar', 'fromDate', 'toDate', 'selectedDate', 'searchStartDate', 'selectedMonth', 'numberOfNights', 'loading', 'nightOptions', 'searchableMonths']),
  },

  watch: {
    // eslint-disable-next-line object-shorthand
    selectedMonth(current) {
      this.yearMonth = current;
    },
  },

  mounted() {
    // if data endpoint returns calendar data that is not the current month's, then move the
    // calendar to the correct month
    this.fetchAvailability({})
      .then(() => {
        let month = null;
        let year = null;
        this.yearMonth = this.selectedMonth;
        if (this.selectedDate) {
          month = this.selectedDate.date.getMonth() + 1;
          year = this.selectedDate.date.getFullYear();
        } else if (this.searchStartDate) {
          month = this.searchStartDate.getMonth() + 1;
          year = this.searchStartDate.getFullYear();
        }

        if (month && year) {
          const { calendar } = this.$refs;
          if (calendar.$refs && calendar.$refs.pages.length !== 0) {
            const { page } = calendar.$refs.pages[0];
            if (page && (page.month !== month || page.year !== year)) {
              calendar.move({ month, year });
            }
          }
        }
      });
  },

  methods: {
    ...mapMutations('availabilityStore', ['setLoading']),
    ...mapActions('availabilityStore', ['fetchAvailability', 'updateSelectedDate']),

    getDateString(val) {
      return util.dateString(val);
    },

    updateDates(data, event) {
      // because IE (all versions including edge) resolves '2020-01' as '2019-12-31T16:00:00'
      const date = util.newDate(`${event.target.value}-01T00:00:00`);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      data.move({ month, year });
    },

    getClassForDay({ day }) {
      const classes = [];

      const cellDate = day.date;
      if (this.selectedMonth) {
        classes.push(sameMonth(this.selectedMonth, cellDate) ? 'nt-same-month' : 'nt-another-month');
      }

      if (this.loading) {
        classes.push('nt-loading');
        return classes.join(' ');
      }

      const availability = this.calendar[util.dateString(cellDate)];
      if (!availability) {
        classes.push('nt-no-data'); // this represents where we haven't fetched api data, and this needs an api change to fix
        return classes.join(' ');
      }

      if (bookable(availability)) {
        classes.push('nt-checkin-date');
      }
      classes.push(available(availability) ? 'nt-available' : 'nt-unavailable');

      if (this.selectedDate) {
        const daysBetween = (cellDate.getTime() - this.selectedDate.date.getTime())
          / (1000 * 3600 * 24);

        if (daysBetween >= 0 && daysBetween < this.numberOfNights + 1) {
          classes.push('nt-selected');
          if (daysBetween >= 1) {
            classes.push('nt-selected-next-days');
          }
        }
      }

      return classes.join(' ');
    },

    onDayClick(event) {
      this.updateSelectedDate(event.date);
    },

    async onNextMonth(event) {
      const { month, year } = event;
      const nights = this.numberOfNights;

      if (month && year && nights) {
        await this.fetchAvailability({ month, year, nights });
      }
    },

    isFirstOfMonth(day) {
      return day.day === 1;
    },

    monthLabel(day) {
      const date = new Date(day.date);
      return date.toLocaleString('default', { month: 'short' });
    },

    hasPrice(data) {
      const { date } = data.day;
      const day = this.calendar[util.dateString(date)];
      return day && day.holiday && day.holiday.actualCost && day.holiday.bookable === true;
    },

    getPriceForDay(data) {
      const { date } = data.day;
      const day = this.calendar[util.dateString(date)];
      return (Math.round(day.holiday.actualCost * 100) / 100).toFixed();
    },

    updateNights(event) {
      const year = this.selectedMonth.substring(0, 4);
      const month = this.selectedMonth.substring(5);
      this.fetchAvailability({ year, month, nights: event.target.value });
    },
  },
};
</script>

<style lang="scss" scoped>
  //noinspection CssInvalidPropertyValue
  button {
    display: block;
    width: 100%;
    background: transparent;
    border: 0;
    outline: 0;
  }
</style>


<style lang="scss">

  @import '../assets/scss/_variables.scss';

  //noinspection CssUnknownTarget
  .vc-day .vc-pointer-events-none {
    // override v-calendar's disabling of interactions
    // with next and previous month dates
    pointer-events: all;
  }
  .nt-hol-availability-calendar__price {
    font-size: 80% !important;
    .nt-day-content span {
      line-height: 1.1 !important;
    }
    .c-day .c-day-content-wrapper span:first-child {
      padding-top: 0 !important;
    }
    .nt-day {
      transition: background-color 200ms linear;
      background-color: transparent;
    }
  }
  .c-day-content-wrapper {
    display: block !important;
    margin: 1px;
  }
  .vc-reset * {
    line-height: 1;
  }
  .vc-day {
    margin: 1px;
  }
  .vc-opacity-0 {
    opacity: 1;
  }
  .vc-border {
    border: 0
  }
  [data-tst='no-price']:after {
    content: '\0020';
    white-space: pre;
    display: block;
  }
  .nt-day-content {
    font-size: 12px;
    span {
      display: block;
      text-align: center;
    }
  }
  @media (min-width: 300px) {
    .nt-day-content {
      font-size: calc(14px + 4 * (100vw - 420px) / 420);
    }
  }
  @media (min-width: 420px) {
    .nt-day-content {
      font-size: 16px;
    }
  }
  .nt-hol-availability-calendar {
    container-name: calendar;
    container-type: inline-size;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    .c-header {
      align-items: flex-start;
      margin: auto;
      width: 90%;
      .c-arrow-layout {
        margin-top: 0.5em;
      }
    }
    .c-weeks {
      /*height: 500px;*/
    }
    .vc-weekday {
      color: $ntd-black;
      font-weight: 600;
    }
    .c-day {
      .c-day-content-wrapper {
        span {
          display: block;
          text-align: center;
          font-weight: 800;
        }
      }
    }
    .nt-day {
      margin: 0;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      box-sizing: border-box;
      &.nt-unavailable {
        color: $ntd-doveGray;
        cursor: default;
        [data-tst='label']{
          font-weight: 400;
          text-decoration: line-through;
        }
      }
      .nt-month-label {
        font-size: 14px;
        font-weight: bold;
      }
      &.nt-available {
        cursor: default;
        border-color: $ntd-black;
        border-width: 2px;
        &.nt-checkin-date {
          cursor: pointer;
        }
      }
      .nt-holiday-price {
        color: $ntd-black;
        font-size: 14px;
        font-weight: 400;
      }
      &.nt-same-month,
      &.nt-another-month {
        &.nt-checkin-date {
          color: $ntd-black;
          font-weight: bold;
          &:hover {
            background-color: $nt-color-wild-sand;
          }
        }
        &.nt-selected {
          .nt-holiday-price {
            color: $ntd-white;
          }
          border-color: $ntd-black;
          background-color: $ntd-black;
          color: $ntd-white;
          &:hover {
            background-color: $ntd-black;
          }
        }
      }
      &.nt-loading {

      }
      &.nt-no-data {
        visibility: hidden;
      }
      &.nt-selected-next-days {
        .nt-hol-availability-calendar__price {
          display: block;
          visibility: hidden;
        }
      }
    }
    .nt-day-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .label {
        font-size: 14px;
        font-weight: 500;
      }
    }
    @container calendar (width < 340px) {
      .nt-day {
        .nt-day-content  {
          font-size: 12px;
        }
        .nt-month-label {
          font-size: 11px;
        }
        &.nt-available {
          border-width: 1px;
        }
      }
    }
  }
</style>
