<template>
  <div class="vld-parent">
    <loading :active.sync="this.loading()"
             :can-cancel="false"
             :is-full-page="false"/>
    <div class="nt-hol-booking-card">
      <div class="nt-hol-availability-book">
        <h4>Check availability</h4>
        <AvailabilityCalendar class="nt-hol-availability-calendar"/>
        <Summary/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from 'vue-loading-overlay';
import AvailabilityCalendar from './components/AvailabilityCalendar.vue';
import Summary from './components/Summary.vue';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'app',

  components: {
    Loading,
    AvailabilityCalendar,
    Summary,
  },

  methods: {
    ...mapGetters('availabilityStore', ['loading']),
  },
};
</script>

<style lang="scss">
  @import './assets/scss/main.scss';
</style>

<style lang="scss" scoped>
  .nt-hol-availability-book {
    padding: 0.5em;

    h4 {
      font-weight: normal;
      font-size: 27px;
      line-height: 1.125;
      margin: 0 0 .5em 0;
    }
  }
  /*
  Internet Explorer 10 & 11 : Because -ms-high-contrast is Microsoft-specific (and only available in
  IE 10+), it will only be parsed in Internet Explorer 10 and greater.
   */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /*
  styling fixes for IE11 - see https://github.com/nathanreyes/v-calendar/issues/303
  This is just some basic styling to have it working, more needs to be done
  */
    .nt-hol-booking-card {
      -ms-overflow-style: -ms-autohiding-scrollbar;
      font-size: 0.6em;
    }
  }
</style>
